import { Grid, Typography, useMediaQuery } from "@mui/material";
import SettingsInputComponentOutlinedIcon from "@mui/icons-material/SettingsInputComponentOutlined";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import AdbIcon from "@mui/icons-material/Adb";
import FeatureVerticalCard from "../component/FeatureVerticalCard";
import theme from "../theme/index";

export default function FeatureSection(props) {
  return (
    <div>
      <Grid container mt={10}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: 35,
                md: 45,
              },
            }}
          >
            Best Ever Services Snuffbox
          </Typography>
          <Typography
            my={1}
            variant="h2"
            sx={{
              fontSize: {
                xs: 25,
                md: 30,
              },
              color: theme.palette.textGray.main,
            }}
          >
            If you are planning on developing a product and app or twebsite,take
            a look at this beautiful
          </Typography>
        </Grid>
        <Grid
          container
          spacing={5}
          my={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // flexDirection: "column",
          }}
        >
          <Grid item xs={10} md={3}>
            <FeatureVerticalCard
              icon={
                <SettingsInputComponentOutlinedIcon
                  style={{ fontSize: "35px", color: "white" }}
                />
              }
              background="linear-gradient(304deg, #1A73E9 39.1%, rgba(26, 115, 233, 0.75) 83.89%)"
              shadow="drop-shadow(1px 3px 6px rgba(26, 115, 233, 0.50))"
              color="#1A73E9"
              title="Phase Status"
              discription="Time support available on application to control motor"
            />
          </Grid>
          <Grid item xs={10} md={3}>
            <FeatureVerticalCard
              icon={
                <SettingsRemoteIcon
                  style={{ fontSize: "35px", color: "white" }}
                />
              }
              color="#78BE20"
              title="Remote Monitor"
              shadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
              background="linear-gradient(304deg, #78BE20 39.1%, #7CC152 83.89%)"
              discription="Time support available on application to control motor"
            />
          </Grid>
          <Grid item xs={10} md={3}>
            <FeatureVerticalCard
              icon={
                <AccessAlarmsIcon
                  style={{ fontSize: "35px", color: "white" }}
                />
              }
              color="#8E67BE"
              title="Timer Support"
              shadow="drop-shadow(1px 3px 6px rgba(142, 103, 190, 0.50))"
              background="linear-gradient(304deg, #8E67BE 39.1%, #8E67BE 83.89%)"
              discription="Time support available on application to control motor"
            />
          </Grid>
          <Grid item xs={10} md={3}>
            <FeatureVerticalCard
              icon={<AdbIcon style={{ fontSize: "35px", color: "white" }} />}
              color="#FF92B0"
              title="Andriod App"
              shadow="drop-shadow(1px 3px 6px rgba(255, 146, 176, 0.50))"
              background="linear-gradient(304deg, #FF92B0 39.1%, #FF92B0 83.89%)"
              discription="Time support available on application to control motor"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
