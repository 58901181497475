import React from "react";
import { Paper, Typography, Button } from "@mui/material";
import theme from "../theme/index";
function PriceSection(props) {
  return (
    <Paper
      elevation={6}
      my={3}
      sx={{
        backgroundColor: "fff",
      }}
      style={{
        borderTop: props.borderTopColor,
        boxShadow: props.shadow,
        borderRadius: "30px",
        textAlign: "center",
        padding: "30px",
      }}
    >
      <Typography variant="hb2" my={3}>
        {props.title}
      </Typography>
      <Typography variant="h2" color={props.offerColor}>
        {props.offer}
      </Typography>

      <Typography variant="h5" mt={3} color="#8E989F">
        Per Month
      </Typography>

      <Typography variant="h5" my={3} color="#8E989F">
        Free per month for one year, excluding 40 free per month for
      </Typography>

      <Button
        style={{
          border: props.isTrue ? props.borderDetails : "none",
          backgroundColor: props.bg,
          color: props.dye,
          fontSize: "15px",
          padding: "5px 50px",
          borderRadius: "40px",
          fontWeight: "400",
          height: "38px",
          marginTop: "20px",
        }}
      >
        Buy Now
      </Button>
    </Paper>
  );
}

export default PriceSection;
