// LanguageSelector.js
import React from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, Box } from "@mui/material";

const LanguageSelector = () => {
  const { i18n, t } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Select
      value={i18n.language}
      onChange={changeLanguage}
      sx={{
        "& fieldset": {
          border: "none",
        },
      }}
    >
      <MenuItem value="en">{t("English")}</MenuItem>
      <MenuItem value="hi">{t("Hindi")}</MenuItem>
    </Select>
  );
};

export default LanguageSelector;
