import theme from "./index";
export default {
  h1: {
    fontWeight: 500,
    fontSize: 50,
    fontFamily: "Poppins, sans-serif",
  },
  hb1: {
    fontWeight: 1000,
    fontSize: 50,
    fontFamily: "Poppins, sans-serif",
  },
  hb2: {
    fontWeight: 600,
    fontSize: 30,
    fontFamily: "Poppins, sans-serif",
  },
  h2: {
    fontWeight: 500,
    fontSize: 30,
    fontFamily: "Poppins, sans-serif",
    lineHeight: "1.4",
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    fontFamily: "Poppins, sans-serif",
  },
  h4: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "Poppins, sans-serif",
  },
  hb4: {
    fontWeight: 800,
    fontSize: 20,
    fontFamily: "Poppins, sans-serif",
  },
  h5: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Poppins, sans-serif",
  },
  h6: {
    fontWeight: 500,
    fontSize: 45,
    fontFamily: "Poppins, sans-serif",
    lineHeight: "1.3",
  },
  overline: {
    fontWeight: 500,
    fontFamily: "Poppins, sans-serif",
  },
  footer: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "Roboto, Poppins, sans-serif",
  },
  // button: {
  //   fontSize: 15,
  //   fontFamily: "Poppins, sans-serif",
  // },
};
