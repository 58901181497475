import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import typography from "./typography";
const theme = createTheme({
  palette: {
    primary: {
      main: "#7cc152", //78BE20//45BE51
      contrastText: "#fff",
    },
    secondary: {
      main: "#ff4081",
    },
    textPrimary: {
      main: "#000000",
    },
    textGray: {
      main: "#8E989F",
      main2: "#37474F", // darker than main
    },
    bgBlue: {
      main: "#1A73E9",
    },
    bgViolet: {
      main: "#8E67BE",
    },
    bgPink: {
      main: "#FF92B0",
    },
    bgWhite: {
      main: "#FFFFFF",
    },
  },
  typography,
});

export default theme;
