import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import theme from "../theme";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

const ContactUs = () => {
  const schema = yup.object().shape({
    name: yup.string().min(3).required("Name is required"),
    message: yup.string().required("Message is required"),
    email: yup
      .string()
      .email("Invalid email")
      .required("Email Address is required"),
    subject: yup.string().min(3).required("Subject is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      subject: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      handlesubmitForm(values);

      // alert(JSON.stringify(values, null, 2));
    },
  });

  const handlesubmitForm = async (formData) => {
    console.log("formData", formData);
    try {
      const response = await axios.post(
        "http://localhost:3001/contact",
        formData
      );
      console.log(response.data);
      // Handle successful submission (e.g., show success message to user)
    } catch (error) {
      alert("Error submitting form:", error);
      console.error("Error submitting form:", error);
      // Handle error (e.g., show error message to user)
    }
  };
  return (
    <div>
      <Grid container spacing={2} mt={10}>
        <Grid item sm={12} md={6}>
          {/* <img src={logo} alt="logo" /> */}
          <Typography variant="hb2">Reach Us</Typography>
          {/* <Box> */}
          <Typography variant="h4" mt={2}>
            Near Gunvantrao Deshmukh School, Bits&Volts pvt. ltl.
            <br />
            Pusad, Yavatmal, Maharashtra -445204
          </Typography>
          <Typography
            mt={1}
            variant="h4"
            style={{
              color: `${theme.palette.textGray.main}`,
            }}
          >
            <a
              href="mailto:support@bitsandvolts.in"
              style={{ color: `${theme.palette.textGray.main}` }}
            >
              support@bitsandvolts.in
            </a>
          </Typography>
          <Typography
            variant="h4"
            mt={1}
            style={{
              color: `${theme.palette.textGray.main}`,
            }}
          >
            +91 9873 342 234
          </Typography>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.3325395304414!2d-122.01116148467422!3d37.33463524513264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb59127ce078f%3A0x18e1c3ce7becf1b!2sApple%20Park!5e0!3m2!1sen!2sin!4v1637309850935!5m2!1sen!2sin"
            width="100%"
            height="275"
            style={{ border: "0", marginTop: "20px" }}
            loading="lazy"
          ></iframe>
        </Grid>
        <Grid item sm={12} md={6} mt={{ xs: 2, md: 0 }}>
          <Typography variant="hb2">Get In Touch</Typography>
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="name"
                  label="Name"
                  sx={{ backgroundColor: theme.palette.bgWhite.main }}
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  sx={{ backgroundColor: theme.palette.bgWhite.main }}
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>
            <TextField
              fullWidth
              style={{
                marginTop: "20px",
              }}
              id="subject"
              label="Subject"
              variant="outlined"
              sx={{ backgroundColor: theme.palette.bgWhite.main }}
              onChange={formik.handleChange}
              value={formik.values.subject}
              error={formik.touched.subject && Boolean(formik.errors.subject)}
              helperText={formik.touched.subject && formik.errors.subject}
            />
            <TextField
              fullWidth
              multiline
              style={{
                marginTop: "20px",
              }}
              id="message"
              label="Messsage"
              sx={{ backgroundColor: theme.palette.bgWhite.main }}
              variant="outlined"
              rows={5}
              onChange={formik.handleChange}
              value={formik.values.message}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#78BE20",
                marginTop: "30px",
                borderRadius: "50px",
              }}
            >
              Send Message
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactUs;
