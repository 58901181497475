import { Box, Container, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import PriceRange from "./PriceRange";
import Feature from "./FeatureSection";
import FeatureHorizontalCard from "../component/FeatureHorizontalCard";
// import { TbWorld } from "react-icons/tb";
import router from "../Images/2nd product png (1).png";
import Backgroundarc from "../Images/backgroundArcs.svg";
import theme from "../theme/index";

import Navbar from "../component/Navbar";
import CustomerStories from "../component/CustomerStories";
import ContactUs from "../component/ContactUs";
import Footer from "../component/Footer";
import PaymentDialog from "../component/PaymentDialog";
import Grid from "@mui/material/Grid";
import Image from "../Images/HomeScreen.jpg";
import Image2 from "../Images/DeviceDetail.jpg";
import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

const HomePage = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const navigateBuyNow = () => {
    // 👇️ navigate to /
    navigate("/buy-now");
  };
  return (
    <Box display="flex" flexDirection="column">
      <Container maxWidth="xl" sx={{ paddingX: 3 }}>
        <Navbar />

        <Grid container spacing={2} mt={5}>
          <Grid item sm={12} md={6}>
            <Typography variant="h5">12 day free</Typography>
            <Typography variant="h1" sx={{ lineHeight: 1.4 }}>
              Ready to launch your app in one the, Our Apna Product
            </Typography>
            <Typography variant="hb1" component="span" sx={{ lineHeight: 1.6 }}>
              Seed Connect
            </Typography>
            <Typography variant="h4">
              Find the best application in the appie. we are also available on
              Play Store for android user
            </Typography>
            <Box height={40} />
            <Button
              variant="contained"
              style={{
                borderRadius: "50px",
                paddingRight: "40px",
                paddingLeft: "40px",
              }}
              onClick={navigateBuyNow}
              // onClick={handleClickOpen}
            >
              Buy Now
            </Button>
            <Typography
              style={{ color: "#78BE20", opacity: 0.21 }}
              sx={{
                fontSize: { xs: "60px", lg: "90px" },
                fontWeight: 700,
                font: "Roboto Serif",
              }}
            >
              CONNECT
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box
              sx={{
                textAlign: "center",
                margin: "auto",
                maxWidth: "100%", // Add this line to ensure image doesn't overflow
              }}
            >
              <img
                src={router}
                alt="router image"
                style={{ maxWidth: "100%", height: "auto", width: "500px" }}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={12} sx={{}}>
            <Feature />
          </Grid>
          {/* MOBILE FEATURES SECTION (MOCKUP FIRST) */}
          <Grid
            container
            sx={{ px: 3, mt: { md: 20, xs: 10 }, justifyContent: "center" }}
          >
            <Grid
              item
              sm={12}
              md={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "70%" },
                  textAlign: "center",
                  paddingTop: "30px",
                  display: "flex",
                  justifyContent: "center",

                  alignItems: "center",
                  backgroundColor: `rgba(124, 193, 82, 0.5)`,
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                }}
              >
                <img
                  src={Image}
                  alt="Chatbot"
                  style={{
                    maxWidth: "75%",
                    width: "230px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item sm={12} md={6} my={5}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: {
                    xs: 35,
                    md: 45,
                  },
                }}
              >
                Automate the water pump with 3.5k Only
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: theme.palette.textGray.main }}
                my={1}
              >
                Lorem ipsum dolor sit amet, consectetur
              </Typography>
              <FeatureHorizontalCard
                iconBackground="linear-gradient(304deg, #7CC152 39.1%, rgba(124, 193, 82, 0.75) 83.89%)"
                iconShadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
                title="User Friendly Design"
                discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
              />
              <FeatureHorizontalCard
                iconBackground="linear-gradient(304deg, #7CC152 39.1%, rgba(124, 193, 82, 0.75) 83.89%)"
                iconShadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
                title="User Friendly Design"
                discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
              />
            </Grid>
          </Grid>

          {/* MOBILE FEATURES SECTION (MOCKUP Secound) */}
          <Grid
            container
            sx={{
              px: 3,
              marginTop: { md: 20, xs: 0 },
              justifyContent: "center",
            }}
          >
            <Grid item sm={12} md={6} my={5}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: {
                    xs: 35,
                    md: 45,
                  },
                }}
              >
                Automate the water pump with 3.5k Only
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: theme.palette.textGray.main }}
                my={1}
              >
                Lorem ipsum dolor sit amet, consectetur
              </Typography>
              <FeatureHorizontalCard
                iconBackground="linear-gradient(304deg, #7CC152 39.1%, rgba(124, 193, 82, 0.75) 83.89%)"
                iconShadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
                title="User Friendly Design"
                discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
              />
              <FeatureHorizontalCard
                iconBackground="linear-gradient(304deg, #7CC152 39.1%, rgba(124, 193, 82, 0.75) 83.89%)"
                iconShadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
                title="User Friendly Design"
                discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "70%" },
                  textAlign: "center",
                  paddingTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: `rgba(124, 193, 82, 0.5)`,
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                }}
              >
                <img
                  src={Image2}
                  alt="Chatbot"
                  style={{
                    maxWidth: "75%",
                    width: "230px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            position: "absolute",
            right: 0,
            zIndex: -1,
          }}
        >
          <img src={Backgroundarc} alt="Logo" />
        </div>
        <CustomerStories />
        <PriceRange />
        <ContactUs />
        <Footer />
      </Container>
      <PaymentDialog open={open} handleClose={handleClose}></PaymentDialog>
    </Box>
  );
};

export default HomePage;
