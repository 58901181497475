import { Typography, Paper } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import theme from "../theme";

export default function FeatureHorizontalCard(props) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: props.iconBackground,
            filter: props.iconShadow,
            marginRight: "20px",
          }}
        >
          <CheckIcon
            style={{
              backgroundColor: props.color,
              borderRadius: "50%",
              color: "white",
              fontSize: "30px",
            }}
          />
        </div>
        <div>
          <Typography variant="h4" fontWeight={500} my={1}>
            {props.title}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "100",
              color: theme.palette.textGray.main,
            }}
          >
            {props.discription}
          </Typography>
        </div>
      </div>
    </>
  );
}
