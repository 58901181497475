import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import router from "../Images/2nd product png (1).png";
import theme from "../theme/index";
import { useFormik, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as yup from "yup";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { SeedWebsiteService, OTPSERVICE } from "../api/url";
import Navbar from "../component/Navbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import rupayLogo from "../Images/rupay-logo-icon.svg";
import phonepeLogo from "../Images/phonepe-logo-icon.svg";
import masterCardLogo from "../Images/master-card-icon.svg";
import googlePayLogo from "../Images/google-pay-logo.svg";

const steps = ["   Details", "Shipping Address", "Payment"];
const devices = [
  {
    value: "seed",
    label: "SEED Connect Model A",
  },
];

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const PersonalDetailsForm = ({
  onNext,
  handleBack,
  handleNext,
  active,
  data,
}) => {
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOTP] = useState("");
  const [otpValid, setOTPValid] = useState(false);
  const [otpError, setOTPError] = useState();
  const [formData, setFormData] = useState("");
  const [showSendOTP, setShowSendOTP] = useState(true);
  const [showSendOTPLoding, setShowSendOTPLoding] = useState(false);
  const [timer, setTimer] = useState(59); // Timer in seconds
  const [showTimer, setShowTimmer] = useState(false);
  const [showResend, setShowResend] = useState(false);

  const schema = yup.object().shape({
    fullName: yup.string().min(3).required("Full Name is required"),
    mobileNumber: yup.string().required("Mobile Number is required"),
    emailAddress: yup
      .string()
      .email("Invalid email")
      .required("Email Address is required"),
    selectedDevice: yup.string().min(3).required("Please select a device"),
  });

  const formik = useFormik({
    initialValues: {
      fullName: data.fullName || "",
      emailAddress: data.emailAddress || "",
      mobileNumber: data.mobileNumber || "",
      selectedDevice: data.selectedDevice || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setFormData(values);
      setShowSendOTPLoding(true);
      handleSendOTP(values);
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const handleSendOTP = async (data) => {
    // call on submit form

    console.log("DATA", data);

    const mobileNumber = `+${data.mobileNumber}`;
    let reqData = JSON.stringify({
      userId: mobileNumber,
      phone: mobileNumber,
      company: "seed",
      data: {
        test: "no",
      },
    });
    let config = {
      method: "post",
      url: `${OTPSERVICE}otp/send`,
      headers: {
        "api-key":
          "439KrFkWsoulXX2dZSvBtiNsT9j3MtciwDYpFkhzDgPgRnRp123nF1K0cVWCHWlTu0QQY3SmJq9KWHWpWUggd1q1G4qNakgFoA1RXAbwmpOzbkoNfaOHJy2s7M6kkE7",
        "Content-Type": "application/json",
      },
      data: reqData,
    };
    console.log("Config", config);
    await axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setShowSendOTPLoding(false);
        setShowSendOTP(false);

        startTimer();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const startTimer = () => {
    setShowTimmer(true);
    let decreasing = true;
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 59) {
          decreasing = false;
        } else if (prevTimer === 0 && !decreasing) {
          clearInterval(interval);
          decreasing = true;
          setShowResend(true);

          return 0;
        }
        if (!decreasing) {
          console.log("Timmer", prevTimer - 1);
          return prevTimer - 1;
        }
      });
    }, 1000);
  };

  const handleResendOTP = async () => {
    const mobileNumber = `+${data.mobileNumber}`;
    let reqData = JSON.stringify({
      userId: mobileNumber,
      phone: mobileNumber,
    });
    let config = {
      method: "PATCH",
      url: `${OTPSERVICE}otp/resend`,
      headers: {
        "api-key":
          "439KrFkWsoulXX2dZSvBtiNsT9j3MtciwDYpFkhzDgPgRnRp123nF1K0cVWCHWlTu0QQY3SmJq9KWHWpWUggd1q1G4qNakgFoA1RXAbwmpOzbkoNfaOHJy2s7M6kkE7",
        "Content-Type": "application/json",
      },
      data: reqData,
    };
    await axios
      .request(config)
      .then((response) => {
        console.log("HAndle resend Response", JSON.stringify(response.data));
        setShowResend(false);
        setShowTimmer(true);
        setTimer(59);
        startTimer();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleValidateOTP = async () => {
    console.log("handleValidateOTP Clicked >>>", otp, otp.length);
    console.log("Form Data >>>", formData);
    const mobileNumber = `+${formData.mobileNumber}`;
    console.log("User ID >>>", mobileNumber);

    let reqData = JSON.stringify({
      otp: otp,
      userId: mobileNumber,
    });

    let config = {
      method: "post",
      url: `${OTPSERVICE}otp/verify`,
      headers: {
        "api-key":
          "439KrFkWsoulXX2dZSvBtiNsT9j3MtciwDYpFkhzDgPgRnRp123nF1K0cVWCHWlTu0QQY3SmJq9KWHWpWUggd1q1G4qNakgFoA1RXAbwmpOzbkoNfaOHJy2s7M6kkE7",
        "Content-Type": "application/json",
      },
      data: reqData,
    };
    if (otp.length === 6) {
      await axios
        .request(config)
        .then((response) => {
          if (
            response.data.verified === true &&
            response.data.error === false
          ) {
            setOTPValid(true);
          } else {
            setOTPError("Enter Incorrect OTP");
          }
        })
        .catch((error) => {
          setOTPError("Enter Correct OTP");
          console.log(error);
          setOTPValid(false);
        });
    } else {
      setOTPValid(false);
      setOTPError("Enter Valid OTP");
    }
  };

  const handleClickNext = () => {
    onNext(formData);
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <TextField
        fullWidth
        id="fullName"
        name="fullName"
        label="Full Name"
        variant="outlined"
        margin="normal"
        onChange={formik.handleChange}
        value={formik.values.fullName}
        error={formik.touched.fullName && Boolean(formik.errors.fullName)}
        helperText={formik.touched.fullName && formik.errors.fullName}
      />
      <TextField
        fullWidth
        id="emailAddress"
        name="emailAddress"
        label="Email Address"
        variant="outlined"
        margin="normal"
        onChange={formik.handleChange}
        value={formik.values.emailAddress}
        error={
          formik.touched.emailAddress && Boolean(formik.errors.emailAddress)
        }
        helperText={formik.touched.emailAddress && formik.errors.emailAddress}
      />
      <TextField
        id="selectedDevice"
        name="selectedDevice"
        select
        fullWidth
        label="Select"
        onChange={formik.handleChange}
        value={formik.values.selectedDevice}
        variant="outlined"
        margin="normal"
        error={
          formik.touched.selectedDevice && Boolean(formik.errors.selectedDevice)
        }
        helperText={
          formik.touched.selectedDevice && formik.errors.selectedDevice
        }
      >
        {devices.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      {/* <Box sx={{ flex: "1 1 auto" }} /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "rows",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <PhoneInput
            id="mobileNumber"
            name="mobileNumber"
            specialLabel=""
            country="in"
            disabled={!showSendOTP}
            onChange={(value) => {
              console.log(value.length);
              if (value.length === "12") {
                setShowOTP(true);
              } else {
                setShowOTP(false); // Ensure showOTP is false for other lengths
              }
              formik.setFieldValue("mobileNumber", value);
            }}
            value={formik.values.mobileNumber}
            inputProps={{
              required: true,
              autoFocus: true,
              countryCodeEditable: false,
            }}
            inputStyle={{
              borderColor:
                formik.touched.mobileNumber && formik.errors.mobileNumber
                  ? "red"
                  : "",
            }}
          />
        </Box>
        {formik.values.fullName &&
          !formik.errors.fullName &&
          formik.values.emailAddress &&
          !formik.errors.emailAddress &&
          formik.values.selectedDevice &&
          !formik.errors.selectedDevice &&
          formik.values.mobileNumber &&
          !formik.errors.mobileNumber &&
          formik.values.mobileNumber.length === 12 && (
            <>
              {showSendOTP && (
                <Button type="submit" variant="contained">
                  Send OTP{" "}
                  {showSendOTPLoding ? (
                    <CircularProgress color="inherit" />
                  ) : null}
                </Button>
              )}

              {showTimer && !showResend && <Typography>00: {timer}</Typography>}
              {showResend && (
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleResendOTP}
                >
                  Resend OTP
                </Button>
              )}
            </>
          )}
      </Box>
      {showTimer && !showResend && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "rows",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            id="otp"
            name="otp"
            label="Enter OTP"
            variant="outlined"
            margin="normal"
            value={otp}
            onChange={(e) => {
              const enteredValue = e.target.value;
              // Check if enteredValue is a number and its length is 6
              if (/^\d{0,6}$/.test(enteredValue)) {
                setOTP(enteredValue);
              }
            }}
            helperText={otpError} // Display error message
            error={!!otpError}
          />
          <Box>
            <Button
              type="button"
              onClick={handleValidateOTP}
              variant="contained"
              disabled={otp.length < 6}
            >
              Validate OTP
            </Button>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="button"
          variant="contained"
          sx={{ mt: 2 }}
          disabled={!otpValid}
          onClick={handleClickNext}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

const PaymentModeForm = ({ handleClickPay, codPayment }) => {
  const [codChecked, setCodChecked] = useState(false);
  const [onlineChecked, setOnlineChecked] = useState(false);
  const handleCodChange = () => {
    setCodChecked(true);
    setOnlineChecked(false);
  };

  const handleOnlineChange = () => {
    setCodChecked(false);
    setOnlineChecked(true);
  };
  return (
    <>
      <Box container px={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Price:</Typography>
          <Typography variant="h5">+3299₹</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.textGray.main,
          }}
        >
          <Typography variant="h5">GST:</Typography>
          <Typography variant="h5">+299₹</Typography>
        </Box>
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Total Cost:</Typography>
          <Typography variant="h4">3499₹</Typography>
        </Box>
      </Box>
      <Box mt={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={onlineChecked}
              onChange={handleOnlineChange}
              inputProps={{ "aria-label": "online" }}
            />
          }
          label={
            <Typography variant="h4" component="div">
              Online Payment
            </Typography>
          }
        />
      </Box>
      <Box px={2} my={1}>
        <img
          src={googlePayLogo}
          style={{ maxWidth: "30px", marginRight: "20px" }}
          alt="Logo"
        />
        <img
          src={phonepeLogo}
          style={{ maxWidth: "30px", marginRight: "20px" }}
          alt="Logo"
        />
        <img
          src={masterCardLogo}
          alt="Logo"
          style={{ maxWidth: "50px", marginRight: "20px" }}
        />
        <img
          src={rupayLogo}
          alt="Logo"
          style={{ maxWidth: "50px", marginRight: "20px" }}
        />
      </Box>
      <Box mt={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={codChecked}
              onChange={handleCodChange}
              inputProps={{ "aria-label": "cod" }}
            />
          }
          label={
            <Typography variant="h4" component="div">
              Cash on Delivery
            </Typography>
          }
        />
      </Box>

      {onlineChecked && (
        <Button
          type="button"
          variant="contained"
          sx={{ mt: 2 }}
          onClick={handleClickPay}
        >
          Pay Now
        </Button>
      )}

      {codChecked && (
        <Button
          type="button"
          variant="contained"
          sx={{ mt: 2 }}
          onClick={codPayment}
        >
          Buy Now
        </Button>
      )}
    </>
  );
};
const ShippingAddressForm = ({ onNext, handleBack, handleNext, active }) => {
  const schema = yup.object().shape({
    houseNumber: yup.string().min(3).required(),
    landmark: yup.string().min(3).required(),
    area: yup.string().min(3).required(),
    pincode: yup.string().min(3).required(),
    town: yup.string().min(3).required(),
    state: yup.string().min(3).required(),
  });

  const formik = useFormik({
    initialValues: {
      houseNumber: "",
      landmark: "",
      area: "",
      pincode: "",
      town: "",
      state: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      onNext(values);
      handleMakePayment();
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const handleMakePayment = () => {
    console.log("PAY CLICKED");
  };
  return (
    <Box
      component="form"
      //   sx={{
      //     "& > :not(style)": { m: 1, width: "50ch" },
      //   }}
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <TextField
        fullWidth
        id="houseNumber"
        label="House No, Appartment, Building"
        variant="outlined"
        margin="normal"
        onChange={formik.handleChange}
        value={formik.values.houseNumber}
        error={formik.touched.houseNumber && Boolean(formik.errors.houseNumber)}
        helperText={formik.touched.houseNumber && formik.errors.houseNumber}
      />
      <TextField
        fullWidth
        id="landmark"
        label="Landmark"
        variant="outlined"
        margin="normal"
        onChange={formik.handleChange}
        value={formik.values.landmark}
        error={formik.touched.landmark && Boolean(formik.errors.landmark)}
        helperText={formik.touched.landmark && formik.errors.landmark}
      />
      <TextField
        fullWidth
        id="area"
        label="Area, Street, Sector, Village"
        variant="outlined"
        margin="normal"
        onChange={formik.handleChange}
        value={formik.values.area}
        error={formik.touched.area && Boolean(formik.errors.area)}
        helperText={formik.touched.area && formik.errors.area}
      />
      <TextField
        fullWidth
        id="pincode"
        label="Pin Code"
        variant="outlined"
        margin="normal"
        onChange={formik.handleChange}
        value={formik.values.pincode}
        error={formik.touched.pincode && Boolean(formik.errors.pincode)}
        helperText={formik.touched.pincode && formik.errors.pincode}
      />
      <TextField
        fullWidth
        id="town"
        label="Town/City"
        variant="outlined"
        margin="normal"
        onChange={formik.handleChange}
        value={formik.values.town}
        error={formik.touched.town && Boolean(formik.errors.town)}
        helperText={formik.touched.town && formik.errors.town}
      />
      <TextField
        fullWidth
        id="state"
        label="State"
        variant="outlined"
        margin="normal"
        onChange={formik.handleChange}
        value={formik.values.state}
        error={formik.touched.state && Boolean(formik.errors.state)}
        helperText={formik.touched.state && formik.errors.state}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          type="button"
          variant="contained"
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>

        <Button type="submit" variant="contained">
          PAY
        </Button>
      </Box>
    </Box>
  );
};

export default function BuyNowPage(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [active, setActive] = useState(false);
  const [personalData, setPersonalData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderFail, setOrderFail] = useState(false);

  const price = 3499;

  const totalSteps = () => {
    return steps.length;
  };

  const handleSubmitPersonalForm = (formData) => {
    console.log("Form data submitted:", formData);
    setPersonalData(formData);
    handleComplete();
  };
  const handleSubmitShippingAddressForm = (formData) => {
    console.log("Form data submitted:", formData);
    setAddressData(formData);
    console.log(personalData);
    handleComplete(true);
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const renderForm = () => {
    switch (activeStep) {
      case 0:
        return (
          <PersonalDetailsForm
            onNext={handleSubmitPersonalForm}
            data={personalData}
            handleBack={handleBack}
            handleNext={handleNext}
            active={active}
          />
        );
      case 1:
        return (
          <ShippingAddressForm
            onNext={handleSubmitShippingAddressForm}
            handleBack={handleBack}
            handleNext={handleNext}
            active={active}
          />
        );
      case 2:
        return (
          <PaymentModeForm
            handleClickPay={displayRazorpay}
            codPayment={codPayment}
          />
        );
      default:
        return null;
    }
  };

  async function codPayment() {
    const { emailAddress, fullName, mobileNumber } = personalData;
    const { area, houseNumber, landmark, pincode, state, town } = addressData;
    const data = await fetch(`${SeedWebsiteService}create/order/cod`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        amount: price,
        houseNumber,
        landmark,
        area,
        pincode,
        town,
        state,
        fullName,
        emailAddress,
        mobileNumber,
        paymentType: "cod",
      }),
    })
      .then((t) => t.json(), setOrderSuccess(true))

      .catch((error) => console.error("Error:", error), setOrderFail(true));
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. are you online");
      return;
    }
    const { emailAddress, fullName, mobileNumber } = personalData;
    const { area, houseNumber, landmark, pincode, state, town } = addressData;
    const data = await fetch(`${SeedWebsiteService}create/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        amount: price,
        houseNumber,
        landmark,
        area,
        pincode,
        town,
        state,
        fullName,
        emailAddress,
        mobileNumber,
        paymentType: "online",
      }),
    })
      .then((t) => t.json(), setOrderSuccess(true))
      .catch((error) => console.error("Error:", error), setOrderFail(true));

    console.log(data);

    const options = {
      key: "rzp_test_eZBbomv0MgujDw",
      amount: data.amount,
      currency: "INR",
      name: "Bits and Volts Private Limited",
      description: "Please pay here to confirm the order.",
      image:
        "https://res.cloudinary.com/darshh/image/upload/v1697558265/BNV_9_zcqswl.png",
      order_id: data.orderId,
      handler: async function (response) {
        console.log(response);
        const verifData = await fetch(`${SeedWebsiteService}verify/payment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({
            ...response,
            mode: "test",
            orderId: data.orderId,
          }), // Replace with "live" when live mode is needed
        }).then((t) => t.json());

        // setIsValid(response.razorpay_payment_id);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: {
        name: fullName,
        contact: mobileNumber,
      },
      remember_customer: true,
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <Box display="flex" flexDirection="column" alignItems={"center"}>
      {orderFail && (
        <Alert severity="error">
          <AlertTitle>Order Fail to Create</AlertTitle>
          Due to technical error Order fail to create.
        </Alert>
      )}
      {orderSuccess && (
        <Alert severity="success">
          <AlertTitle>Success Place SuccessFul</AlertTitle>
          Your Order has been Created, Thank You.
        </Alert>
      )}
      <Grid container spacing={2} maxWidth="xl" sx={{ paddingX: 3 }}>
        <Navbar />
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            mt: { xs: 0, lg: 20 },
            display: "flex",
            // alignItems: "center",
            justifyContent: "center",
          }}
          maxWidth={"100%"}
        >
          <Box px={1}>
            <Stepper activeStep={activeStep} sx={{ my: 2 }}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                    Step {activeStep + 1}
                  </Typography>
                  {renderForm()}
                </React.Fragment>
              )}
            </div>
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          md={7}
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
            position: "relative",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="833"
            height="808"
            viewBox="0 0 833 808"
            fill="none"
          >
            <path
              d="M595.7 3.37601L833 0V838H0L595.7 3.37601Z"
              fill="#7CC152"
            />
          </svg>
          <img
            src={router}
            alt="Your Image Alt Text"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "70%",
              maxHeight: "70%",
            }}
          ></img>
        </Grid>
      </Grid>
    </Box>
  );
}
