import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import LanguageSelector from "../multilingual/LanguageSelector";
import Logo from "../Images/Asset 2 1.svg";
import theme from "../theme/index";

const pages = ["Home", "About Us"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "white", boxShadow: "none", mt: 3 }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* Menu button for small screens */}

        {/* Logo */}
        {/* <Box> */}
        <img src={Logo} alt="logo" height={"40px"} />
        {/* </Box>s */}

        {/* Navigation links for medium and larger screens */}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} ml={6}>
          {pages.map((page) => (
            <Typography
              key={page}
              variant="h4"
              mx={2}
              component={Link} // Use Link component for navigation
              to={page == "Home" ? `/` : `/${page.toLowerCase()}`}
              sx={{
                my: 3,
                color: `${theme.palette.textGray.main}`,
                display: "block",
                textDecoration: "none", // Remove underline from links
              }}
            >
              {page}
            </Typography>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {/* Language selector */}
          <LanguageSelector />
          <IconButton
            size="large"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          {/* User settings */}
          {/* <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
          <IconButton sx={{ p: 0 }}>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
          </IconButton>
          </Tooltip>
        </Box> */}
        </Box>
      </Toolbar>

      {/* Responsive drawer for small screens */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <List>
          {pages.map((page) => (
            <ListItem key={page}>
              <Typography
                key={page}
                variant="h4"
                mx={2}
                component={Link} // Use Link component for navigation
                to={page == "Home" ? `/` : `/${page.toLowerCase()}`}
                sx={{
                  my: 1,
                  color: `${theme.palette.textGray.main}`,
                  display: "block",
                  textDecoration: "none", // Remove underline from links
                }}
              >
                {page}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
