import "./App.css";
import HomePage from "./view/HomePage";
import AboutUsPage from "./view/AboutUsPage";
import BuyNowPage from "./view/BuyNowPage";
import * as React from "react";
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css";
import { ThemeProvider } from "@mui/material/styles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import theme from "./theme/index";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/about us",
    element: <AboutUsPage />,
  },
  {
    path: "/buy-now",
    element: <BuyNowPage />,
  },
  // {
  //   path: "/",
  //   element: <ContactUsPage />,
  // },
  // {
  //   path: "/",
  //   element: <WarrantyPage />,
  // },
  // {
  //   path: "/",
  //   element: <Home />,
  // },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </ThemeProvider>
  );
}

export default App;
