import { Box, Typography, Paper } from "@mui/material";
import theme from "../theme";

export const TestimonialCard = (props) => {
  return (
    <Paper sx={{ padding: 3, height: "100%" }}>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <img
          height={"70px"}
          width={"70px"}
          style={{
            border: "3px solid",
            borderColor: theme.palette.primary.main,
            borderRadius: "50%",
          }}
          src={props.imgPath}
          alt="profilepic"
        />
        <Box ml={2}>
          <Typography variant="hb4" color={theme.palette.primary.main}>
            {props.uName}
          </Typography>
          <Typography variant="h5" color={theme.palette.textGray.main}>
            Farmer
          </Typography>
        </Box>
      </Box>
      <Typography mt={4} variant="h5">
        {props.discription}
      </Typography>
    </Paper>
  );
};
