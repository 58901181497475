import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import PriceSection from "../component/PriceSection";
import theme from "../theme/index";
// import { styled, createTheme, ThemeProvider } from "@mui/system";

// const useStyles = makeStyles((theme) => ({
//   heading: {
//     position: "relative",
//     fontSize: "45px",
//     fontWeight: "700",
//     top: "6%",
//     left: "3%",
//   },

//   explain: {
//     fontSize: "30px",
//     fontWeight: "400",
//     lineHeight: "55px",
//     position: "relative",
//     top: "25%",
//     left: "3%",
//     color: "#8E989F",
//   },
//   container: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
//   gridContainer: {
//     display: "flex",
//     justifyContent: "center",
//     position: "relative",
//   },
// }));

function PriceRange() {
  return (
    <Box
      mt={{ xs: 10, md: 20 }}
      sx={{
        textAlign: "center",
        alignItems: "baseline",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: 35,
                md: 45,
              },
            }}
          >
            Affordable Pricing Model
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            my={2}
            variant="h2"
            sx={{
              color: theme.palette.textGray.main,
              fontSize: {
                xs: 25,
                md: 30,
              },
            }}
          >
            With Considering Each Farmer With Considering Each Farmer
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        mt={1}
        spacing={5}
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <Grid item xs={11} md={4}>
          <PriceSection
            title="Silver"
            offer="Free"
            offerColor="#1A73E9"
            borderTopColor="5px solid #1A73E9"
            borderDetails="2px solid #1A73E9"
            isTrue={true}
            shadow="0px 4px 4px 0px rgba(26, 115, 233, 0.25)"
            dye="#1A73E9"
            bg="#fff"
          />
        </Grid>
        <Grid item xs={11} md={4}>
          <PriceSection
            title="Gold"
            offer="$149"
            offerColor="#7CC152"
            borderTopColor="5px solid #7CC152"
            button="#7CC152"
            isTrue={false}
            shadow="0px 4px 4px 0px rgba(26, 115, 233, 0.25)"
            dye="#fff"
            bg="#7CC152"
          />
        </Grid>
        <Grid item xs={11} md={4}>
          <PriceSection
            title="Platinum"
            offer="$349"
            offerColor="#8E67BE"
            borderTopColor="5px solid #8E67BE"
            button="#FFFFFF"
            borderDetails="2px solid #8E67BE"
            isTrue={true}
            shadow="0px 4px 4px 0px rgba(26, 115, 233, 0.25)"
            dye="#8E67BE"
            bg="#fff"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PriceRange;
