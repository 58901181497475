import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Grid } from "@mui/material";
import tree from "../Images/Brazuca Tree 2.png";
import mylogo from "../Images/Asset 2 1.svg";
// import mylogo from "../Images/logo2.jpg";
import theme from "../theme";
import Instagram from "@mui/icons-material/Instagram";
import Facebook from "@mui/icons-material/Facebook";
import Twitter from "@mui/icons-material/Twitter";
import Fab from "@mui/material/Fab";

const Footer = () => {
  return (
    <Grid container spacing={5} mt={10}>
      <Grid item xs={12} sm={6} md={3}>
        <Grid item>
          <img height={"40px"} src={mylogo} alt="logo image" />
        </Grid>
        <Grid item mt={3}>
          <Typography variant="footer">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo
            amet posuere porta vitae mi accumsan. Ultricies
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Grid item>
          <Typography
            variant="footer"
            fontWeight={700}
            color={theme.palette.textPrimary}
          >
            Services
          </Typography>
        </Grid>

        <Grid item mt={1}>
          <Typography variant="footer" color={theme.palette.textGray.main}>
            Design & Development
          </Typography>
        </Grid>
        <Grid item mt={1}>
          <Typography variant="footer" color={theme.palette.textGray.main}>
            Management & Marketing
          </Typography>
        </Grid>
        <Grid item mt={1}>
          <Typography variant="footer" color={theme.palette.textGray.main}>
            Strategy & Research
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Grid item>
          <Typography
            variant="footer"
            fontWeight={700}
            color={theme.palette.textPrimary}
          >
            Contact
          </Typography>
        </Grid>
        <Grid item mt={1}>
          <Typography variant="footer" color={theme.palette.textGray.main}>
            Pusad, Yavatmal, Maharashtra- 445204
          </Typography>
        </Grid>
        <Grid item mt={1}>
          <Typography variant="footer" color={theme.palette.textGray.main}>
            Help: +91 9873 342 234
          </Typography>
        </Grid>

        <Grid item mt={1}>
          <Typography variant="footer" color={theme.palette.textGray.main}>
            Email:{" "}
            <a
              href="mailto:support@bitsandvolts.in"
              style={{ color: `${theme.palette.textGray.main}` }}
            >
              support@bitsandvolts.in
            </a>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        sx={{
          display: { xs: "none", sm: "flex" },
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <img
          height={"200px"}
          src={tree}
          style={{ marginLeft: "5px", marginRight: "5px" }}
          alt="twiter logo"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        mb={3}
        // sx={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "flex-end",
        // }}
      >
        <Grid
          item
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Fab
            color="primary"
            size="medium"
            sx={{ mr: 2 }}
            aria-label="add"
            href="https://www.instagram.com/"
          >
            <Instagram color="bgWhite" />
          </Fab>
          <Fab
            color="primary"
            size="medium"
            sx={{ mr: 2 }}
            aria-label="add"
            href="https://www.facebook.com/"
          >
            <Facebook color="bgWhite" />
          </Fab>
          <Fab
            color="primary"
            size="medium"
            sx={{ mr: 2 }}
            aria-label="add"
            href="https://twitter.com/"
          >
            <Twitter color="bgWhite" />
          </Fab>
        </Grid>
      </Grid>
      <div
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   alignItems: "center",
      //   justifyContent: "center",
      //   textAlign: "center",
      // }}
      >
        {/* <Typography variant="footer" color={theme.palette.textGray.main} mt={1}>
          Design & Development
        </Typography> */}
      </div>
    </Grid>
  );
};

export default Footer;
