import { Typography, Paper } from "@mui/material";

import theme from "../theme";

export default function FeatureVerticalCard(props) {
  return (
    <Paper
      elevation={6}
      sx={{
        borderTop: "5px solid",
        borderColor: props.color,
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        px: 2,
      }}
    >
      <div
        style={{
          width: "70px",
          height: "70px",
          borderRadius: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: props.background,
          filter: props.shadow,
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        {props.icon}
      </div>
      <Typography variant="h4" my={2}>
        {props.title}
      </Typography>
      <Typography
        variant="h5"
        mb={2}
        sx={{ color: `${theme.palette.textGray.main}` }}
      >
        {props.discription}
      </Typography>
    </Paper>
  );
}
