import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FeatureHorizontalCard from "../component/FeatureHorizontalCard";
import router from "../Images/Farmer.png";
import theme from "../theme/index";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import Grid from "@mui/material/Grid";

const AboutUsPage = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column">
      <Container maxWidth="xl">
        <Navbar />
        <Grid container spacing={2} mt={5}>
          <Grid item sm={12} md={12}>
            <Typography variant="h5">12 day free</Typography>
            <Typography variant="h1">
              Ready to launch your app in one the,
            </Typography>
            <Typography variant="hb1" component="span">
              Application
            </Typography>
            <Typography variant="h4">
              Find the best application in the appie.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Grid item sm={12} md={12} mt={20}>
        <Box
          sx={{
            textAlign: "center",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              paddingTop: "30px",
              background: `linear-gradient(71deg, #7CC152 48.18%, rgba(124, 193, 82, 0.85) 97.64%)`,
              marginTop: { xs: "60px", sm: "200Px" },
            }}
          >
            <Container maxWidth="xl">
              <img
                src={router}
                alt="router image"
                style={{ marginTop: "-200px", maxWidth: "100%" }}
              />
              <Grid
                item
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  textAlign: "initial",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: {
                      xs: 35,
                      md: 45,
                    },
                  }}
                  mt={2}
                  color={theme.palette.bgWhite.main}
                >
                  Join our Team to contribute our vision.
                </Typography>
                <Typography
                  variant="h2"
                  mb={10}
                  mt={2}
                  sx={{
                    fontSize: {
                      xs: 25,
                      md: 30,
                    },
                  }}
                  fontWeight={400}
                  color={theme.palette.bgWhite.main}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et
                  integer tincidunt cras gravida sodales amet at morbi. Sit
                  molestie ac ut malesuada. Pellentesque nunc, nec sapien,
                  pellentesque.
                </Typography>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Grid>
      <Container maxWidth="xl">
        <Grid container mt={7} spacing={1}>
          <Grid item sm={12} md={12}>
            <Typography
              variant="h6"
              sx={{
                fontSize: {
                  xs: 35,
                  md: 45,
                },
              }}
            >
              Automate the water pump with 3.5k Only
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: theme.palette.textGray.main }}
              my={1}
            >
              Lorem ipsum dolor sit amet, consectetur
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <FeatureHorizontalCard
              iconBackground="linear-gradient(304deg, #7CC152 39.1%, rgba(124, 193, 82, 0.75) 83.89%)"
              iconShadow="drop-shadow(1px 3px 6px rgba(124, 193, 82, 0.50))"
              title="User Friendly Design"
              discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
            />
            <FeatureHorizontalCard
              iconBackground="linear-gradient(304deg, #1A73E9 39.1%, #1A73E9 83.89%)"
              iconShadow="drop-shadow(1px 3px 6px rgba(26, 115, 233, 0.50))"
              title="User Friendly Design"
              discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <FeatureHorizontalCard
              iconBackground="linear-gradient(304deg, #FF92B0 39.1%, #FF92B0 83.89%)"
              iconShadow="drop-shadow(1px 3px 6px rgba(255, 146, 176, 0.50))"
              title="User Friendly Design"
              discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
            />
            <FeatureHorizontalCard
              iconBackground="linear-gradient(304deg, #8E67BE 39.1%, rgba(142, 103, 190, 0.75) 83.89%)"
              iconShadow="drop-shadow(1px 3px 6px rgba(142, 103, 190, 0.50))"
              title="User Friendly Design"
              discription="Lorem ipsum dolor sit amet, consectetur adipisc amet,"
            />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl">
        <Footer />
      </Container>
    </Box>
  );
};

export default AboutUsPage;
